<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <p class="p-title">基础信息</p>
            <el-form ref="form" v-bind:rules="rules" v-bind:model="form" label-position="left" label-suffix=":" size="large" label-width="110px" class="dialog_box">
                <el-form-item label="活动名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入活动名称" style="margin-bottom:10px;"></el-input>
                </el-form-item>
                <el-form-item label="位置" prop="position">
                    <el-select v-model="form.position" placeholder="请选择位置" @focus="changePosition" style="margin-bottom:10px;">
                        <el-option v-for="items in positionList" :key="items.index" :label="items.name" :value="items.key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="起止时间" prop="time">
                    <el-date-picker ref="drawDate" :picker-options="pickerOptions" style="margin-bottom:10px;" v-model="form.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="活动logo" prop="logo">
                    <el-input v-model="form.logo" class="form-input" placeholder="请输入图片链接" style="margin-bottom:10px;"></el-input>
                    <el-button @click.prevent="choiceImg('logo')">选择图片</el-button>
                    <el-upload class="avatar-uploader up img1" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleLogoSuccess" :before-upload="beforeLogoUpload" name="file" :headers="header">
                        <img v-if="form.logo" :src="form.logo" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸100*100像素</span>
                </el-form-item>
                <el-form-item label="活动背景图" prop="image">
                    <el-input v-model="form.image" class="form-input" placeholder="请输入图片链接"></el-input>
                    <el-button @click.prevent="choiceImg('image')">选择图片</el-button>
                    <el-upload class="avatar-uploader up img2" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleBannerSuccess" :before-upload="beforeBannerUpload" name="file" :headers="header">
                        <img v-if="form.image" :src="form.image" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸750*300像素</span>
                </el-form-item>
                <el-form-item label="活动海报" prop="poster">
                    <el-input v-model="form.poster" class="form-input" placeholder="请输入图片链接"></el-input>
                    <el-button @click.prevent="choiceImg('poster')">选择图片</el-button>
                    <el-upload class="avatar-uploader up img3" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handlePosterSuccess" :before-upload="beforePosterUpload" name="file" :headers="header">
                        <img v-if="form.poster" :src="form.poster" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸400*400像素</span>
                </el-form-item>
                <el-form-item label="活动封面" prop="thumbs">
                    <Imgupload v-model="thumbs" :ImgVisible="ImgVisible" @changeImg="changeImg" @changetext="changetext" />
                    <span class="span_gray">建议尺寸750*1125像素,可拖动图片调整位置</span>
                </el-form-item>
                <el-form-item label="活动描述" prop="desc">
                    <el-input type="textarea" v-model="form.desc" :rows="1"></el-input>
                </el-form-item>
                <el-form-item label="活动类型" prop="ty">
                    <el-radio-group v-model="form.ty">
                        <el-radio :label="1">N件N元</el-radio>
                        <el-radio :label="2">N件N折</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="">
                    <div class="type">
                        <div class="type_box" v-for="(item,index) in form.tyList" :key="item.index">
                            <div class="box" v-show="form.ty===1">
                                <el-form-item label="件数" :prop="`tyList.${index}.number`" :rules="acceptRulers.number" label-width="60px" style="margin-bottom：20px">
                                    <el-input type="text" placeholder="请输入件数" v-model="item.number"></el-input>
                                    <span class="gray">件</span>
                                </el-form-item>
                                <el-form-item label="金额" :prop="`tyList.${index}.price`" :rules="acceptRulers.price" label-width="60px">
                                    <el-input type="text" placeholder="请输入触发金额" v-model="item.price"></el-input>
                                    <span class="gray">元</span>
                                </el-form-item>
                                <span class="delete_btn" v-if="form.tyList.length>1" @click="deleteTyList(index)"><i class="el-icon-delete"></i></span>
                            </div>
                            <div class="box" v-show="form.ty===2">
                                <el-form-item label="件数" :prop="`tyList.${index}.number`" :rules="acceptRulers.number" label-width="60px" style="margin-bottom：20px">
                                    <el-input type="text" placeholder="请输入触发件数" v-model="item.number"></el-input>
                                    <span class="gray">件</span>
                                </el-form-item>
                                <el-form-item label="折扣" :prop="`tyList.${index}.price`" :rules="acceptRulers.price" label-width="60px">
                                    <el-input type="text" placeholder="请输入折扣" v-model="item.price"></el-input>
                                    <span class="gray">折</span>
                                </el-form-item>
                                <span class="delete_btn" v-if="form.tyList.length>1" @click="deleteTyList(index)"><i class="el-icon-delete"></i></span>
                            </div>
                            <el-button type="primary" @click="addType" v-show="(index == form.tyList.length-1)">
                                添加规格
                            </el-button>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="下单优惠" prop="is_coupon">
                    <el-radio-group v-model="form.is_coupon">
                        <el-radio :label="2">可用</el-radio>
                        <el-radio :label="1">不可用</el-radio>
                    </el-radio-group>
                    <p>设置活动期间商品下单金额是否可参与优惠券</p>
                </el-form-item>
                <el-form-item label="是否推荐" prop="is_recommand">
                    <el-radio-group v-model="form.is_recommand">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                        <el-radio :label="3">已下架</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveData">保 存</el-button>
                    <el-button @click="cancel">取 消</el-button>
                </el-form-item>
                <!-- 选择图片对话框 -->
                <div class="img">
                    <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
                </div>
            </el-form>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import { fetchData } from '@/api/index'
import loadEvents from '@/utils/loading'
import Imgupload from '@/components/Imgupload';
import { deal } from '@/common/main';
import { request } from '@/common/request';
import bus from '@/common/bus';
import Picture from '@/components/Picture';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";
let loads = new loadEvents();
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Imgupload,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            text: 'image',
            mode: 'add',
            menuList: ['营销', '套装购'],
            loading: false,
            rules: {
                name: [{
                    required: true,
                    message: '活动名称不能为空',
                    trigger: 'blur'
                }],
                // position: [{ required: true, message: '位置不能为空', trigger: 'blur' }],
                time: [{
                    required: true,
                    message: '活动时间不能为空',
                    trigger: 'blur'
                }],
                logo: [{ validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }],
                thumbs: [{ validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }],
                poster: [{ validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }],
                image: [{ validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }],
                desc: [{
                    required: true,
                    message: '描述不能为空',
                    trigger: 'blur'
                }],
                ty: [{
                    required: true,
                    message: '活动类型不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '请选择状态',
                    trigger: 'blur'
                }],
                is_coupon: [{
                    required: true,
                    message: '下单优惠不能为空',
                    trigger: 'blur'
                }],
                is_recommand: [{
                    required: true,
                    message: '是否推荐不能为空',
                    trigger: 'blur'
                }],
                position: [{
                    required: true,
                    message: '位置不能为空',
                    trigger: 'blur'
                }],
            },
            mode: 'add',
            form: {
                ty: 1,
                is_coupon: 2,
                is_recommand: 2,
                state: 2,
                tyList: [
                    {
                        number: 0,
                        price: 0
                    }
                ],
            },
            header: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            positionList: [],
            thumbs: [],
            acceptRulers: {
                number: [{
                    required: true,
                    message: '件数不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^[0-9]*$',
                    message: "只能输入数字"
                }],
                price: [{
                    required: true,
                    message: '件数不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
            }
        };
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
    },
    methods: {
        //增加编辑
        saveData() {
            this.form.thumbs = this.thumbs.join(',')
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        this.form.tyList.map(item => {
                            item.number = parseFloat(item.number)
                            item.price = parseFloat(item.price)
                            return item
                        })
                        this.form.stime = deal.timestamp(this.form.time[0]);
                        this.form.etime = deal.timestamp(this.form.time[1]);
                        this.form.list = JSON.stringify(this.form.tyList)
                        request.post('/goods/suit/add', this.form).then(ret => {
                            if (ret.code == 1) {
                                this.$message.success('新增成功');
                                this.$refs.form.clearValidate()
                                bus.$emit('close_current_tags');
                                this.$router.push({ path: '/activity-Suitfor' })
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$refs.form.clearValidate();
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 取消按钮
        cancel() {
            bus.$emit('close_current_tags');
            this.$router.push({ path: '/activity-Suitfor' })
        },
        // 添加规格
        addType() {
            this.form.tyList.push({
                number: null,
                price: null
            })
            this.$forceUpdate()
        },
        // 删除规格
        deleteTyList(index) {
            this.form.tyList.splice(index, 1)
            this.$forceUpdate()
        },
        // 图片上传之前
        beforeLogoUpload(file) {
            loads.open(`.img1 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        beforeBannerUpload(file) {
            loads.open(`.img2 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        beforePosterUpload(file) {
            loads.open(`.img3 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        beforeCoverUpload(file) {
            loads.open(`.img4 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this)
        },
        // 图片上传成功
        handleLogoSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        handleBannerSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'image', res.data.uri);
            }
        },
        handlePosterSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'poster', res.data.uri);
            }
        },
        handleCoverSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'thumbs', res.data.uri);
            }
        },
        // 选择位置
        changePosition() {
            deal.getconf('suit', this)
        },

        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;

        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            if (this.text === 'many') {
                if (this.thumbs.length < 9) {
                    this.thumbs.push(url)
                }
                else {
                    this.$message({ type: 'error', message: '最多上传九张图片' })
                }
            } else {
                this.$set(this.form, this.text, url);
            }
        },
        changeImg(val) {
            this.ImgVisible = val
        },
        changetext(val) {
            this.text = val
        },
    }
};
</script>

<style scoped>
.type {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
}
.type_box {
    display: flex;
}
.box {
    width: 240px;
    padding: 20px 30px 0;
    border: 1px solid #eeeeee;
    margin-right: 20px;
}
.box >>> .el-form-item {
    margin-bottom: 20px;
}
.type_box .box >>> .el-input--large .el-input__inner {
    width: 150px;
    margin-right: 10px;
}
.el-textarea >>> .el-textarea__inner {
    width: 500px !important;
    height: 200px;
}
.p-title {
    font-size: 20px;
    font-weight: 900;
}
.dialog_box {
    padding: 30px;
}
.span_gray {
    font-size: 12px;
    color: gray;
}
.dialog_box >>> .el-select .el-input--large .el-input__inner {
    width: 400px;
}
.dialog_box >>> .el-input--large .el-input__inner {
    width: 201%;
}
.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}
.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}
.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

.dialog_box .up {
    margin-top: 20px;
}
.dialog_box .img1 .avatar-uploader-icon {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .img1 .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .img2 .avatar-uploader-icon {
    font-size: 28px;
    width: 150px;
    height: 60px;
    line-height: 60px;
}
.dialog_box .img2.avatar {
    font-size: 28px;
    width: 150px;
    height: 60px;
    line-height: 60px;
}
.dialog_box .img3 .avatar-uploader-icon {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .img3.avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.container {
    background: #ffffff;
}

.form-input >>> .el-input__inner {
    width: 500px;
}

.el-input--small .el-input__inner {
    width: 500px;
}
/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 220px;
}
.type_box .el-button {
    margin-left: 20px;
}
</style>
